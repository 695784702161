import React from 'react';

const SplitSection = ({ className = '', titleSection, textSection, imgSection, altSection, reverseOrder, noOrder }) => (
  <section className="lg:py-5">
    <div className="flex flex-col lg:flex-row lg-flex-nowrap items-center justify-between px-5 lg:px-0" >
      <div className="w-full lg:w-2/5">
        <h3 className="text-3xl font-semibold leading-tight">
          {titleSection}
        </h3>
        {textSection}
      </div>
      <figure
        className={`flex justify-center items-center mb-10 lg:mb-0 w-full lg:w-1/2 order-first ${reverseOrder && `lg:order-first`} ${noOrder && `lg:order-last`}`}
      >
        <img
          src={imgSection}
          alt={altSection}
          className={`hidden lg:block ${className}`}
        />
      </figure>
    </div>
  </section>
)

export default SplitSection;
