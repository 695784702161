import React from 'react'
import SectionHeader from '../../components/SectionHeader'
import SplitSection from '../../components/SplitSection'
import Link from '../../components/Link';

const Myths = () => (
  <section id="Myths" className="container mx-auto py-16 font-roboto" >
    <SectionHeader className="lg:mb-24">Popular myths about SSL expiration</SectionHeader>

    <SplitSection
      reverseOrder
      titleSection="Lightning doesn't strike twice?"
      textSection={
        <p className="mt-8 text-xl font-light leading-relaxed">
          Looks like that's not the case with SSL expiration. For many websites that expired in 2020, it's possible to google up mentions of prior SSL expirations from 2014-2019.
                  </p>
      }
      imgSection="/images/strike-twice.png"
      altSection="double strike"
      className="w-3/4 h-3/4 lg:w-full lg:h-full object-center"
    />
    <SplitSection
      noOrder
      titleSection="Big websites never expire?"
      textSection={
        <p className="mt-8 text-xl font-light leading-relaxed">
          They do. Websites with Alexa Rank in 20k...200k
          range expire equally often. Also, the longest
          outages happened to enterprise-level websites
          rather than to smaller ones. ¾ of them &nbsp;
          <Link href="https://deepnote.com/@--ae8a/SSL-Pulse-report-fBK137yIQk-cMkKM6iqyKA#Who-had-the-longest-outage%3F" >
            happened over weekends
          </Link>
        </p>
      }
      imgSection="/images/weekends-strike.png"
      altSection="weekends strike"
      className="w-3/4 h-3/4 lg:w-8/12 lg:h-8/12 object-center"
    />
    <SplitSection
      reverseOrder
      titleSection="Expired certificate is easy to notice and fix?"
      textSection={
        <p className="mt-8 text-xl font-light leading-relaxed">
          While SSL expirations are easy to notice, refreshing
          an expired certificate often takes several hours.
          Almost 50% of the affected websites stay down for
          more than an hour, ~10% stay down for more
          than a day (
          <Link href="https://deepnote.com/@--ae8a/SSL-Pulse-report-fBK137yIQk-cMkKM6iqyKA#How-long-does-it-take-to-fix-an-expired-certificate%3F">
            see research
          </Link>
          ).
        </p>
      }
      imgSection="/images/bad-ssl-screenshot.png"
      altSection="new sertificate"
      className="w-3/4 h-3/4 lg:w-full lg:h-full object-center"
    />
  </section>
)

export default Myths
