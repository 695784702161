import React from 'react';
import Layout from '../components/layout/Layout';

// Sections
import ExpirationsInNumbers from '../sections/landing/ExpirationsInNumbers'
import TopSitesStats from '../sections/landing/TopSitesStats'
import MeetTheTeam from '../sections/landing/MeetTheTeam'
import Myths from '../sections/landing/Myths'

// Main page's content

const MainPage = () => (
  // Layout with big alert clock image + h1 + CTA
  <Layout header='hero'>
    {/* SSL expirations in numbers */}
    <ExpirationsInNumbers />

    {/* ~59k sites ... ~1.3% ... > 830k users */}
    <TopSitesStats />

    {/* TODO: put form here */}

    {/* Popular myths about SSL expiration */}
    <Myths />

    {/* Photos + "email" CTA */}
    <MeetTheTeam />
  </Layout>
)

export default MainPage;
