import React from 'react'
import SectionHeader from '../../components/SectionHeader'

const MeetTheTeam = () => (
  <section id="MeetTheTeam" className="pt-20 font-roboto bg-gray-550">
    <div className="container mx-auto pb-16 lg:pt-2">
      <SectionHeader>Meet the team</SectionHeader>

      <div className="px-4 lg:px-0 flex flex-col lg:flex-row items-center lg:justify-around mt-8 lg:mt-16">
        {/* Left column: image with team members in circles */}
        <div className="w-full lg:w-1/2 p-4">
          <img alt="" className="object-cover" src="/images/team.jpg" />
        </div>

        {/* Right column: "Email us" CTA */}
        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start">
          <p className="mb-12 text-xl lg:text-3xl font-light sm:leading-14 text-center lg:text-left">We're here to protect your website from SSL certificate expiration</p>
          <a
            href="mailto:hello@sslpulse.org"
            target="_blank"
            rel="noreferrer"
            className="inline-block w-full sm:w-auto bg-blue-700 hover:bg-blue-400 rounded-full mb-6 py-4 px-14 text-center text-white text-md font-semibold"
          >
            Email us
              </a>
          <p className="text-sm text-gray-500 font-light text-center lg:text-left">Send us any questions or just say "Hello"</p>
        </div>

      </div>
    </div>
  </section>
)

export default MeetTheTeam
