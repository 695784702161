import React from 'react';

const StatsBox = ({ className = '', primaryText, secondaryText }) => (
	<div className={`${className}`}>
		<p
			className="sm:pb-7 text-3xl lg:text-6xl font-bold text-black text-center"
		>
			{primaryText}
		</p>
		<p className="hidden sm:block border-b border-black sm:mx-auto sm:w-11/12"></p>
		<p
			className="pt-4 text-center text-lg font-light"
		>
			{secondaryText}
		</p>
	</div>
);

export default StatsBox;
