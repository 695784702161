import React from 'react';
import Link from '../../components/Link';
import SectionHeader from '../../components/SectionHeader';

const ExpirationsInNumbers = () => (
  <section id="ExpirationsInNumbers" className="container mx-auto text-center pt-20 pb-14 lg:pt-28 lg:pb-20 font-roboto">
    <SectionHeader>SSL expirations in numbers</SectionHeader>

    <p className="text-xl lg:text-2xl mt-8 px-4 lg:font-light">
      2% of Alexa Top 1M websites suffered from SSL expiration this January. See &nbsp;
      <Link href="https://deepnote.com/publish/7c12b5df-bc88-424f-9c32-428cea2ab228">
        full report
      </Link>
    </p>

  </section>
)

export default ExpirationsInNumbers
