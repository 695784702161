import React from 'react';
import StatsBox from '../../components/StatsBox';

const TopSitesStats = () => (
    <section id="TopSitesStats" className="container mx-auto py-5 lg:py-20 font-roboto" >
        <div className="flex flex-col sm:flex-row justify-around">
            <StatsBox
                className="w-full sm:w-1/3"
                primaryText="~59k sites"
                secondaryText="were to renew SSL certificates"
            />
            <StatsBox
                className="w-full mt-10 sm:w-1/3 sm:mt-0"
                primaryText="~1.3%"
                secondaryText="missed the renewal date"
            />
            <StatsBox
                className="w-full mt-10 sm:w-1/3 sm:mt-0"
                primaryText="> 830k users"
                secondaryText="notices SSL errors"
            />
        </div>
    </section >
)

export default TopSitesStats
